import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { myAxiosInstance } from './axiosConfig';
import { StyledBorderCard, StyledButton } from './StyledBootstrap';
import { useTracking } from 'react-tracking';
import { ProfilePicture } from './MiscComponents';
import { useTheme } from 'styled-components';
import MeetingTimer from './MeetingTimer';


function UserPreviewCard({ preview_object, buttonBoolean, bottomReload, setBottomReload }) {
    const themeMode = useTheme();
    const { userSlug, reload, setReload, setActiveChannel, setChatVisible } = useAuth();
    const [isIndividualMessageLoading, setIsIndividualMessageLoading] = useState(false);

    const slug = preview_object.slug;
    const fullName = `${preview_object.first_name} ${preview_object.last_name}`;
    const majors = Array.isArray(preview_object.major_or_grad_program)
        ? preview_object.major_or_grad_program.join(', ')
        : preview_object.major_or_grad_program;
    const university = preview_object.university;
    const company = preview_object.company;

    const [pictureSize, setPictureSize] = useState(0);
    const cardRef = useRef(null);
    const navigate = useNavigate();

    const titleSize = pictureSize * 0.15;
    const majorSize = pictureSize * 0.1;

    const handleUserClick = () => {
        navigate('/dashboard/user/' + preview_object.slug);
    }

    const handleMessageButtonClick = async () => {
        try {
            setIsIndividualMessageLoading(true);
            const response = await myAxiosInstance.post('/api/create-channel/', { recipient_slug: slug });
            setActiveChannel(response.data.channel_id);
            setChatVisible(true);

        }
        catch (error) {
            console.error('Error setting up channel', error.response.data);

        }
        finally {
            setIsIndividualMessageLoading(false);
        }
    };

    const handleUserPreviewButtonClick = () => {

        if (preview_object.meeting_status !== "not_meeting")
        {
            navigate('/dashboard/meeting/' + preview_object.meeting_slug);            
        }
        else if (preview_object.portfolio_status === "in_portfolio"){
            // Chat
            handleMessageButtonClick();
        }
        else{
            navigate('/dashboard/user/' + preview_object.slug, { state: { addToPortfolio: true } });
        }
    }

    const buttonLabels = ["Add", "Sent", "Received", "Confirmed", "Rescheduling", "Reschedule", "Join", "Chat"];
    console.log("PREVIEW OBJECT MEETING STATUS", preview_object);

    useEffect(() => {
        const updateCardSize = () => {
            if (cardRef.current) {
                const cardWidth = cardRef.current.offsetWidth;
                const size = Math.min(cardWidth, cardWidth * 1.6) * 0.7; // Adjusted size when not hovered
                setPictureSize(size);
            }
        };

        updateCardSize();
        window.addEventListener('resize', updateCardSize);

        return () => window.removeEventListener('resize', updateCardSize);
    }, []);

    return (
        <>
            <StyledBorderCard
                ref={cardRef}
                
                style={{
                    width: '8rem',
                    height: buttonBoolean ? '14.3rem' : '12rem', // Fixed size when not hovered
                    transition: 'transform 0.3s ease',
                    transform: 'scale(1)',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    marginBottom: '0rem', // Adjusted margin bottom to reduce space between cards
                    
                }}
                onMouseEnter={() => {
                    cardRef.current.style.transform = 'scale(1.04)';
                    cardRef.current.style.boxShadow = '0 0 20px rgba(0,0,0,0.2)';
                }}
                onMouseLeave={() => {
                    cardRef.current.style.transform = 'scale(1)';
                    cardRef.current.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
                }}
            >
                <StyledBorderCard.Body style={{ textAlign: 'center'}}>
                    <div onClick={handleUserClick}>
                    <div className='d-flex justify-content-center'>
                        <ProfilePicture name={fullName} size={pictureSize} graduation_date={preview_object.graduation_date} picture_link={preview_object.profile_picture} />
                    </div>
                    <StyledBorderCard.Title
                        style={{
                            cursor: 'pointer',
                            textAlign: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: `${titleSize}px`,
                            marginTop: '0.3em'
                        }}>
                        {fullName}
                    </StyledBorderCard.Title>
                    {/*<StyledBorderCard.Text
                        style={{
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            fontSize: `${majorSize}px`,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            lineHeight: '1.2em',
                        }}>
                        {company === "" ? majors || 'Not Specified' : company || 'Not Specified'}
                    </StyledBorderCard.Text>*/}
                    <StyledBorderCard.Text
                        style={{
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            fontSize: `${majorSize}px`,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            lineHeight: '1.2em',
                        }}>
                        {university || 'Not Specified'}
                    </StyledBorderCard.Text>
                    <StyledBorderCard.Text
                        style={{
                            textAlign: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            fontSize: `${majorSize}px`,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            lineHeight: '1.2em',
                        }}>
                        {"Portfolio Size: " + preview_object.portfolio_count}
                    </StyledBorderCard.Text>
                    </div>
                    {buttonBoolean ? 
                    <StyledButton className='mt-2' onClick={handleUserPreviewButtonClick} style={{ lineHeight: '1', fontSize: '0.75rem', minWidth: '95%', backgroundColor: preview_object.meeting_status === "you_requested" || preview_object.meeting_status === "confirmed" || preview_object.meeting_status === "you_rescheduled" ? themeMode.background : themeMode.primary, color: preview_object.meeting_status === "you_requested" || preview_object.meeting_status === "confirmed" || preview_object.meeting_status === "you_rescheduled" ? themeMode.onBackground : themeMode.onPrimary}}>
                        {
                            preview_object.meeting_status === "you_requested" ? buttonLabels[1] :
                            preview_object.meeting_status === "you_received" ? buttonLabels[2] :
                            preview_object.meeting_status === "confirmed" ? <MeetingTimer meetingTime={preview_object.meeting_date} onTime={() => { setBottomReload(bottomReload + 1) }} /> :
                            preview_object.meeting_status === "you_rescheduled" ? buttonLabels[4] :
                            preview_object.meeting_status === "other_user_rescheduled" ? buttonLabels[5] :
                            preview_object.meeting_status === "in_progress" ? buttonLabels[6] :
                            preview_object.portfolio_status === "in_portfolio" ? buttonLabels[7] : 
                            preview_object.meeting_status === "not_meeting" ? buttonLabels[0] : <></>
                        }
                    </StyledButton> : <></>}
                </StyledBorderCard.Body>
            </StyledBorderCard>
        </>
    );
}

export default UserPreviewCard;
