import PoolPreviewCard, {HomePoolPreviewCard} from "./PoolPreviewCard";
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Row, Col, Card, Container } from "react-bootstrap";
import { useAuth } from "./AuthContext";
import Loading from "./Loading";
import { useErrorBoundary } from "react-error-boundary";
import { StyledCard, StyledH5, StyledH4, StyledParagraph, StyledButton } from "./StyledBootstrap";
import { myAxiosInstance } from "./axiosConfig";
import { Link } from "react-router-dom";
import UserPreviewCard from "./UserPreviewCard";
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { useNavigate } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RequestPreviewCard from "./RequestPreviewCard";



const Home = () => {
    console.log("my here is the token");
    console.log(localStorage.getItem('userToken'));

    const navigate = useNavigate();
    const HorizontalSettings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true,
        speed: 7000,
        autoplaySpeed: 7000,
        cssEase: "linear",
        arrows: false
    };

    const VerticalSettings = {
        dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        slidesPerRow: 2,
        autoplay: true,
        autoplaySpeed: 7000,

        vertical: true,
        speed: 5000,
        infinite: true,
        cssEase: "linear",

    };

    const themeMode = useTheme();

    const [dataArray, setDataArray] = useState([]);
    const [suggestedPoolArray, setSuggestedPoolArray] = useState([]);
    const [suggestedUserArray, setSuggestedUserArray] = useState([]);
    const [updatesArray, setUpdatesArray] = useState([]);
    const [meetingsArray, setMeetingsArray] = useState([]);
    const [isBottomLoading, setIsBottomLoading] = useState(true); // make this true
    const [homeUserDetails, setHomeUserDetails] = useState(null);
    const { reload, setReload, emailVerified, universityEmailVerified, poolingRequested, setPoolingRequested, poolingActivated } = useAuth();
    const [bottomReload, setBottomReload] = useState(1);

    const [resentEmailVerification, setResentEmailVerification] = useState(0);
    const [resentUniversityEmailVerification, setResentUniversityEmailVerification] = useState(0);

    const [activatePoolingButtonLoading, setActivatePoolingButtonLoading] = useState(false);


    const handleActivatePoolingClick = async () => {
        try {
            setActivatePoolingButtonLoading(true);
            const response = await myAxiosInstance.put('/api/enable-pooling/', { pooling_requested: true });
            setPoolingRequested(true);
        }
        catch (error) {
            console.error('Error sending portfolio request', error.response.data);
        }
        finally {
            setActivatePoolingButtonLoading(false);
        }

    };



    // const sendVerificationEmail = async (university) => {
    //     try {
    //         console.log("send v email here");

    //         if (university && resentUniversityEmailVerification == 0) {
    //             console.log("send v email here1", university);
    //             setResentUniversityEmailVerification(1);
    //             await myAxiosInstance.post('/api/resend-verification-university-email/');
    //             setResentUniversityEmailVerification(2);

    //         }
    //         else if (resentEmailVerification == 0) {
    //             console.log("send v email here2");
    //             setResentEmailVerification(1);
    //             await myAxiosInstance.post('/api/resend-verification-email/');
    //             setResentEmailVerification(2);
    //         }

    //     }
    //     catch (error) {
    //         showBoundary(error);
    //     }

    // }






    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        const getMyPoolsData = async () => {
            try {
                // console.log(await myAxiosInstance.get('/api/home/'));
                console.log("running use effect before....");
                const response = await myAxiosInstance.get('/api/home/');
                console.log("running use effect after....");
                console.log(response);
                console.log(response.data);
                setDataArray(response.data.todo_pools || []);
                setHomeUserDetails(response.data.user_details);
                setSuggestedPoolArray(response.data.todo_pools);
                setSuggestedUserArray(response.data.daily_picks);
                setMeetingsArray(response.data.todo_meetings);
                setUpdatesArray(response.data.updates);
                console.log("UPDATES", response.data.updates);

                console.log("this is what it is being set to: ");
                console.log(response.data.daily_picks[0]);
            } catch (error) {
                showBoundary(error);
            } finally {
                setIsBottomLoading(false);
            }
        };
        getMyPoolsData();
    }, [reload, bottomReload]);

    const isMdOrLarger = useMediaQuery({ minWidth: 768 });

    return (
        isBottomLoading ? <Loading /> : <>

            <div className='d-flex justify-content-center' style={{ backgroundColor: themeMode.background, minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>
                <div className='home-joyrride mx-2' style={{ minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>

                    {poolingRequested && isMdOrLarger ? <><div><StyledH5 className="mt-4"><strong>Welcome, {homeUserDetails.first_name}</strong></StyledH5></div>

                        <StyledCard>
                            <StyledCard.Body>
                                <Container fluid>

                                    <Row>
                                        <Col className="d-flex flex-column justify-content-center align-items-center">
                                            <div><StyledH4><strong>{homeUserDetails.number_of_pools}</strong></StyledH4></div>
                                        </Col>
                                        <Col className="d-flex flex-column justify-content-center align-items-center">
                                            <div><StyledH4><strong>{homeUserDetails.portfolio_count}</strong></StyledH4></div>
                                        </Col>
                                        <Col className="d-flex flex-column justify-content-center align-items-center">
                                            <div><StyledH4><strong>$0</strong></StyledH4></div>
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col className="d-flex flex-column justify-content-center">

                                            <div className='text-center' >Your Pools</div>
                                        </Col>
                                        <Col className="d-flex flex-column justify-content-center">
                                            <div className='text-center' >Portfolio Size</div>  {/*AMOLI CHANGE */}
                                        </Col>
                                        <Col className="d-flex flex-column justify-content-center">
                                            <div className='text-center' >Net Balance</div>
                                        </Col>
                                    </Row>
                                </Container>
                            </StyledCard.Body>
                        </StyledCard></> : <></>}

                    {!isMdOrLarger && (
                        <>
                            <StyledH5 className="mb-3 mt-3"><strong>Daily Picks</strong></StyledH5>
                            <StyledCard className="mt-3">
                                <StyledCard.Body>

                                    <div style={{ display: 'flex', overflowX: 'scroll', paddingTop: '10px', paddingBottom: '10px' }}>
                                        {suggestedUserArray.filter(otherUserInfo => otherUserInfo.first_name !== '').map((otherUserInfo, index) => (
                                            <div className="mx-2" key={index}>
                                                <UserPreviewCard preview_object={otherUserInfo} buttonBoolean={true} bottomReload={bottomReload} setBottomReload={setBottomReload} />
                                            </div>
                                        ))}
                                    </div>
                                </StyledCard.Body>
                            </StyledCard>
                        </>
                    )}


                    {/*!emailVerified || !universityEmailVerified ?
                        <StyledCard className="mt-3">
                            <StyledCard.Body>
                                <Container fluid>
                                    <Row>
                                        <div><StyledH5><strong>Email Verification</strong></StyledH5></div>
                                    </Row>
                                    <Row>
                                        <StyledParagraph style={{ fontSize: '1.2rem' }} className="mt-3">
                                            To join pools (and get a green checkmark), please verify your email(s).
                                        </StyledParagraph>
                                        {emailVerified ? null : (
                                            <span
                                                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                                className='mt-2'
                                                onClick={() => sendVerificationEmail(false)}
                                            >
                                                {resentEmailVerification === 0 ? 'Resend Email Verification' :
                                                    resentEmailVerification === 1 ? <Loading /> : 'Check Your Email'}
                                            </span>
                                        )}
                                        {universityEmailVerified ? null : (
                                            <span
                                                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                                className='mt-2'
                                                onClick={() => sendVerificationEmail(true)}
                                            >
                                                {resentUniversityEmailVerification === 0 ? 'Resend University Email Verification' :
                                                    resentUniversityEmailVerification === 1 ? <Loading /> : 'Check Your University Email'}
                                            </span>
                                        )}

                                    </Row>
                                </Container>
                            </StyledCard.Body>
                        </StyledCard> : <></>*/}
                    {!poolingActivated && !poolingRequested && isMdOrLarger ? <>
                        <div className="mt-3">
                            <StyledH5><strong>Activate Pooling</strong></StyledH5>
                        </div>
                        <StyledCard style={{ position: 'relative' }}>
                            <StyledCard.Body>
                                <div>
                                    We are currently only allowing a select group of students to access success pools. If you are interested, click activate pooling, and we will screen your profile and reach out if you are selected.
                                </div>

                                {poolingRequested ? <StyledButton className='mt-1' style={{ backgroundColor: themeMode.background, color: themeMode.onBackground }}>
                                    Applied
                                </StyledButton> :
                                    <StyledButton onClick={handleActivatePoolingClick} className='mt-2'>
                                        {activatePoolingButtonLoading ? <Loading /> : <>Activate Pooling</>}
                                    </StyledButton>}

                            </StyledCard.Body>

                        </StyledCard></> : <></>}


                    <div className="mt-3">
                        <StyledH5><strong>For You</strong></StyledH5>
                    </div>
                    <StyledCard style={{ position: 'relative' }}>
                        {meetingsArray.length == 0 ? <StyledCard.Body style={{ fontSize: '1.2rem' }}>No portfolio requests or pool invites. Add people to your portfolio from Daily Picks.</StyledCard.Body> :
                            <>
                                <StyledCard.Body className='d-flex' style={{
                                    overflowX: 'auto',
                                    whiteSpace: 'nowrap',
                                    gap: '1rem',
                                    paddingRight: '1.5rem',
                                    minWidth: 0,
                                    width: '100%'  // Ensure there's space on the right for the gradient
                                }}>
                                    {meetingsArray.map((meeting) => (
                                        <div className="mx-1 my-1">
                                            <RequestPreviewCard meeting={meeting} bottomReload={bottomReload} setBottomReload={setBottomReload} />
                                        </div>
                                    ))}
                                    {dataArray.map((pool) => (
                                        <div className="mx-1 my-1">
                                            <HomePoolPreviewCard data={pool} />
                                        </div>
                                    ))}
                                </StyledCard.Body>
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    width: '50px',  // Adjust width as needed
                                    height: '100%',
                                    background: 'linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0))',
                                    pointerEvents: 'none'  // Allow the scrollable content to be interacted with
                                }}></div></>}
                    </StyledCard>



                    {updatesArray.length == 0 ? <></> :
                        <>
                            <div className="mt-3"><StyledH5><strong>Updates</strong></StyledH5></div>
                            {updatesArray.map((update) => (
                                <StyledCard
                                    onClick={() => { navigate("/" + update.redirect_link); }}
                                    className='my-2'
                                    style={{
                                        cursor: 'pointer', // Change cursor to pointer
                                        transition: 'transform 0.3s ease', // Smooth transition for scaling
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.01)'} // Slightly larger on hover
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'} // Back to original size on hover out
                                >
                                    <div className="d-flex mx-2 my-2 px-1 py-1 align-items-center">
                                        <img
                                            src={update.profile_pic}
                                            alt={"Preview"}
                                            onError={(e) => { e.target.src = 'https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'; }}
                                            style={{
                                                width: '3rem', // Adjust width for less height
                                                height: '3rem', // Adjust height for less height
                                                borderRadius: '50%',
                                                marginRight: '1.5rem'
                                            }}
                                        />
                                        <div style={{ fontSize: '1.1rem' }}>
                                            {update.message}

                                        </div>
                                    </div>


                                </StyledCard>

                            ))}</>}




                </div>



                {/*isMdOrLarger && (
                    <div className="mx-2">
                        <StyledH5 className="mt-4"><strong>Daily Picks</strong></StyledH5>

                        <StyledCard style={{ height: '80vh', overflowY: 'auto' }}>
                            <StyledCard.Body>
                                {suggestedUserArray
                                    .filter(otherUserInfo => otherUserInfo.first_name !== '')
                                    .reduce((acc, curr, index) => {
                                        if (index % 2 === 0) {
                                            // Every even index, create a new row with two elements
                                            acc.push([curr]);
                                        } else {
                                            // Add the current item to the last row
                                            acc[acc.length - 1].push(curr);
                                        }
                                        return acc;
                                    }, [])
                                    .map((pair, pairIndex) => (
                                        <div className="d-flex align-items-center justify-content-center" key={pairIndex}>
                                            {pair.map((otherUserInfo, index) => (
                                                <div className="mx-1 my-2" key={index}>
                                                    <UserPreviewCard preview_object={otherUserInfo} buttonBoolean={true} bottomReload={bottomReload} setBottomReload={setBottomReload} />
                                                </div>
                                            ))}
                                        </div>
                                    ))}

                            </StyledCard.Body>
                        </StyledCard>
                    </div>


                )*/}



            </div >

        </>
    );
}

export default Home;