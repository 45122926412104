import { StyledH2 } from "./StyledBootstrap";
import { useAuth } from './AuthContext';
import { myAxiosInstance } from './axiosConfig';
import { useEffect } from "react";

function Payments() {

    const { userSlug } = useAuth();


    useEffect(() => {

        myAxiosInstance.post('/api/track/', { user_id: userSlug.split('-')[2], event_name: "Payments Tab Click", event_data: {} })
            .then((res) => console.log('Initial tracking sent'))
            .catch((err) => console.log('Initial tracking failed', err));


    }, []);

    return (
        <div className="text-center">
            <StyledH2>Join a pool to unlock this feature...</StyledH2>

        </div>
    );
}

export default Payments;