// ProfilePicture.js

import React from 'react';
import { stringToColor } from './CriticalFunctions';
import { formatGraduationDate } from './CriticalFunctions';
import { useTheme } from 'styled-components';
import { useState } from 'react';

const ImageWithFallback = ({ src, alt, fallbackSrc, ...props }) => (
  <img
    src={src}
    alt={alt}
    onError={(e) => { e.target.src = fallbackSrc; }}
    {...props}
  />
);



export const ProfilePicture = ({ name, size, graduation_date, picture_link }) => {

  const themeMode = useTheme();
  // Function to extract initials from name
  const getInitials = (name) => {
    //console.log("this is what is happening");
    //console.log(name);
    return name.split(' ').map(part => part[0].toUpperCase()).join('');
  };

  const initials = getInitials(name);
  const gradDate = formatGraduationDate(graduation_date);
  const backgroundColor = stringToColor(name);

  // Base font size as a percentage of the size
  const baseFontSize = 0.35 * size; // Adjust percentage as needed
  const gradFontSize = 0.18 * size; // Adjust percentage as needed

  const style = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '50%',
    backgroundColor: backgroundColor,
    color: themeMode.onSurface,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    lineHeight: 'normal', // Adjust as needed
  };

  const initialsStyle = {
    fontSize: `${baseFontSize}px`,
    margin: '0.2em',
    lineHeight: 1, // Adjust as needed for alignment
  };

  const gradStyle = {
    fontSize: `${gradFontSize}px`,
    margin: 0,
    lineHeight: 1, // Adjust as needed for alignment
  };

  return (

    <>
      
        <div style={{
          width: size,
          height: size,
          borderRadius: '50%',
          overflow: 'hidden',
          display: 'inline-block',
          padding: 0
        }}>
          {/*<img
            src={picture_link}
            alt="Preview"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />*/}
          <ImageWithFallback
        src={picture_link ? picture_link : 'https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'}
        alt="Preview"
        fallbackSrc='https://storage.googleapis.com/prosp_profile_img/profile_pictures/main_profile_picture_prospinity-captain-O4zW.png'
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
      />
          
          </div>

        


    </>
  );
};
