import { Nav, Form, FormControl, Row, Col, Container, InputGroup, Dropdown, Card, Stack, Button, FormGroup, FormLabel } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { apiP } from './CriticalFunctions';
import { FormInput } from './FormComponents';
import { useNavigate } from 'react-router-dom';
import MultiSelectUsers from './MultiSelectUsers';
import MultiSelectEmails from './MultiSelectEmails';
import { convertUTCtoLocalDateTime } from './CriticalFunctions';
import "react-datepicker/dist/react-datepicker.css";
import { StyledParagraph, StyledCard, StyledForm, StyledFormCheck, StyledFormControl, StyledFormLabel, StyledFormSelect, StyledFormGroup, StyledButton, StyledModal } from './StyledBootstrap';
import { useAuth } from './AuthContext';
import { myAxiosInstance } from './axiosConfig';


function NewCreatePool() {
    const [message, setMessage] = useState('');
    const [error, setError] = useState({ hasError: false, message: '', step: 1 });

    const { userSlug } = useAuth();

    useEffect(() => {

        setError({ hasError: true, message: message, step: 1 })


    }, [message, setMessage]);

    useEffect(() => {

        myAxiosInstance.post('/api/track/', { user_id: userSlug.split('-')[2], event_name: "Create Pool Click", event_data: {} })
                    .then((res) => console.log('Initial tracking sent'))
                    .catch((err) => console.log('Initial tracking failed', err));


    }, []);


    const getDefaultDeadline = (daysToAdd = 1) => {
        const now = new Date();
        now.setDate(now.getDate() + daysToAdd); // Add default days to current date
        return now.toISOString().slice(0, 16); // Convert to 'YYYY-MM-DDTHH:mm' format
    };


    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [poolInfo, setPoolInfo] = useState({
        name: '',
        description: '',
        threshold: '0',
        contribution_percent: '10',
        time_horizon: '15',
        invite_emails: [],
        invitation_deadline: getDefaultDeadline(1),
        sign_deadline: getDefaultDeadline(2),
        percent_signitures_to_activate: '',
        minimum_income: ''
    });


    const [selectedInvitationDeadline, setSelectedInvitationDeadline] = useState('1 week');
    const [selectedSignDeadline, setSelectedSignDeadline] = useState('1 day');

    const [minimumIncomeOption, setMinimumIncomeOption] = useState(false);
    const [isBottomLoading, setIsBottomLoading] = useState(false);


    const [inviteEmails, setInviteEmails] = useState([]);
    const [inviteUsers, setInviteUsers] = useState([]);


    const invitationPeriodTimes = [
        "1 week",
        "2 weeks",
    ];

    const signingPeriodTimes = [
        "1 day",
        "3 days",
        "1 week",
        "2 weeks",
    ];

    const packages = [
        "Alpha",
        "Gamma",
        "Delta",
        "Omega",
        "Custom",
    ];

    const activation_percentages = [
        "50%",
        "60%",
        "70%",
        "80%",
        "90%",
        "100%"
    ];


    const [poolType, setPoolType] = useState("Custom");
    const [deadlineCustomization, setDeadlineCustomization] = useState(false);


    const [activationPercentage, setActivationPercentage] = useState('70%');


    const goToNextStep = () => {
        setCurrentStep(prev => prev + 1);
    };

    // Function to navigate to the previous form step
    const goToPreviousStep = () => {
        setCurrentStep(prev => prev - 1);
    };





    function handleFieldChange({ field, val }) {
        let newValue = val;

        if (field === 'invited_users') {
            newValue = [val];
        }

        let newPoolInfo = { ...poolInfo, [field]: newValue };
        setPoolInfo(newPoolInfo);
    }

    const parseNumericValue = (value, fieldName) => {
        if (typeof value !== 'string') {
            return value; // If the value is already a number or undefined, return it as is.
        }

        switch (fieldName) {
            case 'threshold':
            case 'buyout':
            case 'minimum_income':
                // Remove the dollar sign and 'k', then multiply by 1000.
                // If the value is 'None', return null.
                // Handle both 'k' and 'm' for thousands and millions respectively.
                if (value.toLowerCase().includes('m')) {
                    return parseInt(value.replace(/[^\d.]/g, '')) * 1000000;
                } else if (value.toLowerCase().includes('k')) {
                    return parseInt(value.replace(/[^\d.]/g, '')) * 1000;
                } else {
                    return parseInt(value);
                }

            case 'contribution_percent':
            case 'percent_signitures_to_activate':
            case 'time_horizon':
                // Remove non-numeric characters.
                return parseInt(value.replace(/[^\d]/g, ''));

            case 'invitation_period_days':
            case 'sign_period_days':
                // Map the string values to corresponding integer values
                switch (value) {
                    case "1 day":
                        return 1;
                    case "3 days":
                        return 3;
                    case "1 week":
                        return 7;
                    case "2 weeks":
                        return 14;
                    default:
                        return parseInt(value);
                }

            default:
                return parseInt(value);
        }
    };

    const prepareData = () => {
        const data = { ...poolInfo };

        if (poolType !== 'Custom') {
            if (poolType === 'Alpha') {
                data.threshold = 1000000;
                data.contribution_percent = 2;
                data.time_horizon = 10;
            }
            else if (poolType === 'Gamma') {
                data.threshold = 500000;
                data.contribution_percent = 5;
                data.time_horizon = 15;
            }
            else if (poolType === 'Delta') {
                data.threshold = 200000;
                data.contribution_percent = 5;
                data.time_horizon = 10;
            }
            else if (poolType === 'Omega') {
                data.threshold = 50000;
                data.contribution_percent = 10;
                data.time_horizon = 5;
            }
        }

        if (!deadlineCustomization) {
            let currentDate = new Date();
            if (selectedInvitationDeadline !== '') {
                currentDate.setDate(currentDate.getDate() + parseNumericValue(selectedInvitationDeadline, "invitation_period_days"));
                data.invitation_deadline = currentDate.toISOString();
            }
            if (selectedSignDeadline !== '') {
                currentDate.setDate(currentDate.getDate() + parseNumericValue(selectedSignDeadline, "sign_period_days"));
                data.sign_deadline = currentDate.toISOString();
            }
        }

        data.percent_signitures_to_activate = parseNumericValue(activationPercentage, "percent_signitures_to_activate");

        data.invite_emails = [...inviteUsers.map(invitee => invitee.email), ...inviteEmails];
        if (!minimumIncomeOption)
        {
            data.minimum_income = 0;
        }
        return data;
    };


    const handleInfoSubmit = async () => {
        const data = prepareData();
        console.log("creating pool data...");
        console.log(data);
        setIsBottomLoading(true);
        await apiP(
            data,
            'create-pools/', // Make sure this endpoint is correct
            setMessage,
            'Pool Created! Taking you to your pools...',
            navigate,
            '/dashboard/home', // Update this navigation path as needed
            null, // Update or remove these parameters as needed
            null,
            null,
            'post'
        );
        setIsBottomLoading(false);
    };


    return (
        <Row className='justify-content-center'>
            <Col className='col-lg-6 col-md-8'>
                <StyledCard>
                    <StyledCard.Body>
                        <StyledCard.Title className='text-center'>Create A New Pool</StyledCard.Title>

                        {currentStep === 1 && (
                            <>
                                <FormInput label="Private Pool Name" dataArr={poolInfo} handleFieldChange={handleFieldChange} fieldName='name' select={false} options={null} />
                                <Row className='justify-content-center'>
                                    <Col md={4}>
                                        <StyledButton variant="primary" onClick={goToNextStep} className="mt-3 w-100">
                                            Next
                                        </StyledButton>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {currentStep === 2 && (
                            <>
                                {/*<StyledFormGroup className="mb-3">
                                    <Form.Label>Select a Pool Type</Form.Label>
                                    <StyledFormSelect defaultValue="Gamma" aria-label="Select option" required value={poolType}
                                        onChange={e => setPoolType(e.target.value)}>
                                        {packages.map(op => (
                                            <option key={op} value={op}>{op}</option>
                                        ))}
                                    </StyledFormSelect>
                                </StyledFormGroup>*/}
                                {poolType === 'Custom' ? <>
                                    {/*<StyledFormGroup className="mb-3">
                                        <StyledFormLabel>Threshold (in dollars):</StyledFormLabel>
                                        <StyledFormControl
                                            type="text"
                                            value={poolInfo.threshold}
                                            onChange={(e) => handleFieldChange({ field: 'threshold', val: e.target.value })}
                                        />
                                    </StyledFormGroup>*/}
                                    <StyledFormGroup className="mb-3">
                                        <Form.Label>Contribution Percentage (in percent):</Form.Label>
                                        <InputGroup>
                                            <StyledFormControl
                                                type="number"
                                                value={poolInfo.contribution_percent}
                                                onChange={(e) => handleFieldChange({ field: 'contribution_percent', val: e.target.value })}
                                            />
                                        </InputGroup>
                                    </StyledFormGroup>
                                    <StyledFormGroup className="mb-3">
                                        <Form.Label>Time Horizon (in years):</Form.Label>
                                        <InputGroup>
                                            <StyledFormControl
                                                type="number"
                                                value={poolInfo.time_horizon}
                                                onChange={(e) => handleFieldChange({ field: 'time_horizon', val: e.target.value })}
                                            />
                                        </InputGroup>
                                    </StyledFormGroup   >
                                    


                                </> :

                                    <Row className='justify-content-center'>

                                        <Col className='d-flex flex-column justify-content-center align-items-center' xs={3}>
                                            <strong>Rate</strong>
                                            {poolType === 'Alpha' ? <div>2%</div> : <></>}
                                            {poolType === 'Gamma' ? <div>5%</div> : <></>}
                                            {poolType === 'Delta' ? <div>5%</div> : <></>}
                                            {poolType === 'Omega' ? <div>10%</div> : <></>}
                                        </Col>

                                        <Col className='d-flex flex-column justify-content-center align-items-center' xs={3}>
                                            <strong>Threshold</strong>
                                            {poolType === 'Alpha' ? <div>$1,000,000</div> : <></>}
                                            {poolType === 'Gamma' ? <div>$500,000</div> : <></>}
                                            {poolType === 'Delta' ? <div>$200,000</div> : <></>}
                                            {poolType === 'Omega' ? <div>$50,000</div> : <></>}
                                        </Col>
                                        <Col className='d-flex flex-column justify-content-center align-items-center' xs={3}>
                                            <strong>Time Horizon</strong>
                                            {poolType === 'Alpha' ? <div>10 years</div> : <></>}
                                            {poolType === 'Gamma' ? <div>15 years</div> : <></>}
                                            {poolType === 'Delta' ? <div>10 years</div> : <></>}
                                            {poolType === 'Omega' ? <div>5 years</div> : <></>}
                                        </Col>

                                    </Row>

                                }
                                <StyledFormCheck
                                    type="switch"
                                    id="custom-switch"
                                    label={minimumIncomeOption ? "Minimum Income On" : "Minimum Income Off"}
                                    checked={minimumIncomeOption === true}
                                    onChange={() => setMinimumIncomeOption(!minimumIncomeOption)}
                                    className='ml-4 mb-3 mt-3'
                                />
                                {minimumIncomeOption ?
                                    <StyledFormGroup className="mb-3">
                                        <Form.Label>Minimum Income:</Form.Label>
                                        <InputGroup>
                                            <StyledFormControl
                                                type="number"
                                                value={poolInfo.minimum_income}
                                                onChange={(e) => handleFieldChange({ field: 'minimum_income', val: e.target.value })}
                                            />
                                        </InputGroup>
                                    </StyledFormGroup> : <></>}
                                    <div>
                                        Want more customization? Send an email to <strong>support@prospinity.com</strong>, and we can set up a custom pool for you.
                                    </div>


                                <Row className='justify-content-center'>
                                    <Col md={4}>
                                        <StyledButton variant="primary" onClick={goToPreviousStep} className="mt-3 w-100">
                                            Back
                                        </StyledButton>
                                    </Col>
                                    <Col md={4}>
                                        <StyledButton variant="primary" onClick={goToNextStep} className="mt-3 w-100">
                                            Next
                                        </StyledButton>
                                    </Col>
                                </Row>
                            </>

                        )}
                        {currentStep === 3 && (
                            <>
                                <StyledFormCheck
                                    type="switch"
                                    id="custom-switch"
                                    label="Fully Customize Deadline"
                                    checked={deadlineCustomization === true}
                                    onChange={() => setDeadlineCustomization(!deadlineCustomization)}
                                    className='ml-3 mb-3'
                                />
                                {deadlineCustomization ? <>
                                    <StyledFormGroup className="mb-3">
                                        <Form.Label>Invitation Deadline (in your timezone):</Form.Label>
                                        <StyledFormControl
                                            type="datetime-local"
                                            value={convertUTCtoLocalDateTime(poolInfo.invitation_deadline) || ''}
                                            onChange={e => setPoolInfo({ ...poolInfo, invitation_deadline: new Date(e.target.value).toISOString() })}
                                        />
                                    </StyledFormGroup>
                                    <StyledFormGroup className="mb-3">
                                        <Form.Label>Sign Deadline (in your timezone):</Form.Label>
                                        <StyledFormControl
                                            type="datetime-local"
                                            value={convertUTCtoLocalDateTime(poolInfo.sign_deadline) || ''}
                                            onChange={e => setPoolInfo({ ...poolInfo, sign_deadline: new Date(e.target.value).toISOString() })}
                                        />
                                    </StyledFormGroup></> :
                                    <>
                                        <StyledFormGroup className="mb-3">
                                            <Form.Label>Invitation Deadline:</Form.Label>
                                            <StyledFormSelect
                                                value={selectedInvitationDeadline}
                                                onChange={e => setSelectedInvitationDeadline(e.target.value)}
                                            >
                                                
                                                {invitationPeriodTimes.map(op => (
                                                    <option key={op} value={op}>{op}</option>
                                                ))}
                                            </StyledFormSelect>
                                        </StyledFormGroup>
                                        <StyledFormGroup className="mb-3">
                                            <Form.Label>Sign Deadline:</Form.Label>
                                            <StyledFormSelect
                                                value={selectedSignDeadline}
                                                onChange={e => setSelectedSignDeadline(e.target.value)}
                                            >
                                                
                                                {signingPeriodTimes.map(op => (
                                                    <option key={op} value={op}>{op}</option>
                                                ))}
                                            </StyledFormSelect>
                                        </StyledFormGroup>
                                    </>
                                }

                                <StyledFormGroup className="mb-3">
                                    <Form.Label>Select an Activation Percentage</Form.Label>
                                    <StyledFormSelect defaultValue="70%" aria-label="Select option" required value={activationPercentage}
                                        onChange={e => setActivationPercentage(e.target.value)}>


                                        {activation_percentages.map(op => (
                                            <option key={op} value={op}>{op}</option>
                                        ))}
                                    </StyledFormSelect>
                                </StyledFormGroup>
                                {/*<StyledFormGroup className="mb-3">
                                    <Form.Label>Percent Signatures to Activate (in percent):</Form.Label>
                                    <InputGroup>
                                        <StyledFormControl
                                            type="number"
                                            value={poolInfo.percent_signitures_to_activate}
                                            onChange={(e) => handleFieldChange({ field: 'percent_signitures_to_activate', val: e.target.value })}
                                        />
                                    </InputGroup>
                                        </StyledFormGroup>*/}


                                <Row className='justify-content-center'>
                                    <Col md={4}>
                                        <StyledButton variant="primary" onClick={goToPreviousStep} className="mt-3 w-100">
                                            Back
                                        </StyledButton>
                                    </Col>
                                    <Col md={4}>
                                        <StyledButton variant="primary" onClick={goToNextStep} className="mt-3 w-100">
                                            Next
                                        </StyledButton>
                                    </Col>
                                </Row></>
                        )}
                        {currentStep === 4 && (
                            <>
                                <MultiSelectUsers
                                    otherUsers={[]}
                                    value={inviteUsers}
                                    onChange={(selected) => {
                                        setInviteUsers(selected);
                                    }}
                                    label="Invite Users"
                                    endpoint={true}
                                />
                                <MultiSelectEmails
                                    value={inviteEmails}
                                    label="Emails"
                                    onChange={(selected) => { setInviteEmails(selected) }}
                                />
                                <StyledParagraph>
                                <>
                                By clicking "Create Pool" and viewing certain sections of our platform, you agree to keep all content, 
                                including the Success Pooling Agreement, confidential and acknowledge that it is the 
                                intellectual property of Prospinity. Creating this pool does not constitute a binding legal 
                                commitment to any terms other than the confidentiality and intellectual property 
                                acknowledgment itself. For full details, see our <a href="/agreement-confidentiality">Confidentiality and Intellectual Property Agreement</a>.
                                </>
                                </StyledParagraph>
                                <Row className='justify-content-center'>
                                    <Col md={4}>
                                        <StyledButton variant="primary" onClick={goToPreviousStep} className="mt-3 w-100">
                                            Back
                                        </StyledButton>
                                    </Col>
                                    <Col md={4}>
                                        <StyledButton variant="primary" onClick={handleInfoSubmit} className="mt-3 w-100">
                                            Create Pool
                                        </StyledButton>
                                    </Col>
                                </Row>
                            </>
                        )}



                        <StyledModal show={error.hasError && error.message !== ''} centered>
                            <StyledModal.Header>{isBottomLoading ? <>Creating Pool...</> : <>Pool Creation Failed</>}</StyledModal.Header>


                            <StyledModal.Body>{error.message}</StyledModal.Body>
                            <StyledModal.Footer>
                                {isBottomLoading ? <></> : <StyledButton onClick={() => { 
                                    
                                    setCurrentStep(error.step); 
                                    setError({ hasError: false, message: '', step: 1 }) }}>Fix</StyledButton>}
                                
                            </StyledModal.Footer>
                            
                        </StyledModal>

                    </StyledCard.Body>
                </StyledCard>
            </Col >
        </Row >

    );
}



export default NewCreatePool;