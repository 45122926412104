import { StyledH2, StyledH1, StyledH3, StyledH4, StyledParagraph, StyledCard, StyledAccordion } from "./StyledBootstrap";
import { useTheme } from "styled-components";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faAngleDown } from '@fortawesome/free-solid-svg-icons';

function FAQs() {
    const themeMode = useTheme();
    return (
        <Row className='justify-content-center my-2' style={{ minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>
            <Col className='col-md-9'>
                <StyledCard className='mb-3'>
                    <StyledCard.Header className='text-center'>
                        <StyledH2 style={{ color: "white" }}>FAQs</StyledH2>
                    </StyledCard.Header>
                    <StyledCard.Body>
                        <StyledAccordion>
                            <StyledAccordion.Item eventKey="0" className='mt-1'>
                                <StyledAccordion.Header>
                                    <StyledH4>How Does a Success Pool Work? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        Each year, members contribute a percentage (the “Rate”) of their income over a threshold (the “Threshold”) to a shared pool. Contributions are made in November based on the previous year’s income, and the pool is shared among members. The process lasts for a set number of years (the “Horizon”).
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="1">
                                <StyledAccordion.Header>
                                    <StyledH4>What Are the Mechanisms of Pool Formation/Creation? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        <strong>Creating or Joining a Pool:</strong> Create a pool, choose a package, and invite members. As the creator, you’re the pool captain. You can also join a pool created by someone else.
                                    </StyledParagraph>
                                    <StyledParagraph>
                                        <strong>Invitation Period:</strong> During this time, members can join or leave the pool, and the terms can be changed. After the deadline, the pool is locked.
                                    </StyledParagraph>
                                    <StyledParagraph>
                                        <strong>Signing Period:</strong> After the invitation deadline, members sign the pooling agreement. The pool activates if enough members sign.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="2">
                                <StyledAccordion.Header>
                                    <StyledH4>Who do I create a pool with? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        You have three options. 
                                        1) Create a new pool and invite friends. 
                                        2) Get matched in a pool by Prospinity, check the members, and decide. 
                                        3) Meet people on the platform and propose to pool together.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="3">
                                <StyledAccordion.Header>
                                    <StyledH4>What is the Portfolio? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        Discover and meet students on Prospinity. Once connected, you’re added to each other’s portfolios, creating a collection of contacts for future collaboration or pooling.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="4">
                                <StyledAccordion.Header>
                                    <StyledH4>What are Daily Picks? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        Every day, you receive 6 suggestions (daily picks) of people you might want to add to your portfolio.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="5">
                                <StyledAccordion.Header>
                                    <StyledH4>Why Should I Pool with Someone Younger/Older Than Me? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        Contribution terms are tied to graduation dates. Cross-generational collaboration fosters diverse perspectives and mutual support over time.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="6">
                                <StyledAccordion.Header>
                                    <StyledH4>Why Should I Join if I Think I’ll Make More Money Than Everyone Else? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        Uncertainty in careers means pooling offers collective hedging. It’s like a venture fund focused on people, not salaries.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="7">
                                <StyledAccordion.Header>
                                    <StyledH4>What Happens If Prospinity Shuts Down? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        The community can choose to assign a new Pool Manager or leave the agreement intact without a platform.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="8">
                                <StyledAccordion.Header>
                                    <StyledH4>How Are Success Pools Taxed? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        Contributions and distributions are netted. Gains are taxed as income, and limited deductions apply for contributors.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="9">
                                <StyledAccordion.Header>
                                    <StyledH4>What Counts as Income? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        Income may include wages, bonuses, and capital gains, but exclude spousal income and inheritance. Definitions can vary by pool.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="10">
                                <StyledAccordion.Header>
                                    <StyledH4>Who Enforces the Legal Side? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        Prospinity partners with legal experts to enforce agreements. Income is verified through tax returns, and disputes follow mechanisms outlined in the agreement.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="11">
                                <StyledAccordion.Header>
                                    <StyledH4>How Does Prospinity Make Money? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        Prospinity takes a 5% commission on pool earnings, ensuring alignment with pool success.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="12">
                                <StyledAccordion.Header>
                                    <StyledH4>What Is the Minimum Threshold? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        If income falls below the minimum threshold (optional feature), a member is excluded from distributions for that year.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                            <StyledAccordion.Item eventKey="13">
                                <StyledAccordion.Header>
                                    <StyledH4>Other Questions? <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledH4>
                                </StyledAccordion.Header>
                                <StyledAccordion.Body>
                                    <StyledParagraph>
                                        For other questions, please email <a href="mailto:support@prospinity.com">support@prospinity.com</a>.
                                    </StyledParagraph>
                                </StyledAccordion.Body>
                            </StyledAccordion.Item>

                        </StyledAccordion>
                    </StyledCard.Body>
                </StyledCard>
            </Col>
        </Row>
    );
}

export default FAQs;