import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Chat, Channel, ChannelList, Window, MessageList, MessageInput as DefaultMessageInput, Thread } from 'stream-chat-react';
import './StreamChatLayout.css';
import { StyledButton } from './StyledBootstrap';
import { useTheme } from 'styled-components';
import { useAuth } from './AuthContext';
import { StreamChat } from 'stream-chat';
import { CustomChannelPreview } from './CustomChannelPreview';
import { CustomChannelHeader } from './CustomChannelHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome for icons
import { faHome, faHandshake, faQuestion, faUser, faSwimmer, faFileInvoiceDollar, faSignOut, faChartLine, faCreditCard, faCheck, faSearch, faBell, faPlus, faGear, faMessage } from '@fortawesome/free-solid-svg-icons'; // Example icons

const CustomMessageInput = (props) => {
  return <DefaultMessageInput {...props} grow />;
};

const Messaging = () => {
  const { userFirstName, userLastName, userSlug, chatToken, profilePictureLink, activeChannel, setActiveChannel, isChatVisible, setChatVisible, chatID } = useAuth();
  const apiKey = process.env.REACT_APP_STREAM_API_KEY;
  const themeMode = useTheme();
  const filters = { members: { $in: [chatID] } };
  const options = { presence: true, state: true };
  const sort = { last_message_at: -1 };

  const [chatClient, setChatClient] = useState(null);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [viewingChannelList, setViewingChannelList] = useState(true);

  const initializeClient = useCallback(async () => {
    const client = StreamChat.getInstance(apiKey);
    const userObject = { id: chatID, name: `${userFirstName} ${userLastName}`, image: profilePictureLink };
    await client.connectUser(userObject, chatToken);
    setChatClient(client);
    checkUnreadMessages(client);
  }, [apiKey, chatID, userFirstName, userLastName, chatToken, profilePictureLink]);

  const checkUnreadMessages = async (client) => {
    const channels = await client.queryChannels(filters, sort, { watch: true, state: true });
    let unreadCount = 0;
    channels.forEach(channel => {
      unreadCount += channel.countUnread();
    });
    setUnreadMessageCount(unreadCount);
    if (unreadCount > 0) {
      setChatVisible(true);
    }
  };

  useEffect(() => {
    if (!chatClient && chatID && chatToken) {
      initializeClient();
    }
    return () => {
      if (chatClient) {
        chatClient.disconnectUser();
        setChatClient(null);
      }
    };
  }, [chatClient, chatID, chatToken, initializeClient]);

  const notificationCountStyle = {
    position: 'absolute',
    top: '-0.5rem',
    right: '-0.5rem',
    background: themeMode.secondary,
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: themeMode.onSecondary,
    fontSize: '0.75rem',
    fontWeight: 'bold',
    zIndex: 1001
  };

  const toggleChat = () => {
    setChatVisible(!isChatVisible);
  };

  const onChannelSelect = (channel) => {
    setActiveChannel(channel);
    setViewingChannelList(false);
    const currentUnread = channel.countUnread();
    setUnreadMessageCount(unreadMessageCount - currentUnread);
  };

  const backToChannelList = () => {
    setViewingChannelList(true);
    setActiveChannel(null);
  };

  const channelListProps = useMemo(() => ({
    filters,
    sort,
    options,
    showChannelSearch: true,
    additionalChannelSearchProps: { searchForChannels: true, popupResults: true },
    Preview: (props) => <CustomChannelPreview {...props} setActiveChannel={onChannelSelect} />,
    ...(activeChannel && { customActiveChannel: activeChannel }),
  }), [filters, sort, options, activeChannel]);

  return (
    <>
      <StyledButton onClick={toggleChat} style={{ position: 'fixed', bottom: '2vh', right: 20, zIndex: 1000, fontSize: '1.5rem' }} className='chat-joyrride'>
        {isChatVisible ? <><FontAwesomeIcon icon={faMessage}></FontAwesomeIcon></> : <><FontAwesomeIcon icon={faMessage}></FontAwesomeIcon></>}
        {!isChatVisible && unreadMessageCount > 0 && (
          <div style={notificationCountStyle}>{unreadMessageCount}</div>
        )}
      </StyledButton>

      {isChatVisible && chatClient && (
        <div style={{ position: 'fixed', bottom: 60, right: 20, maxWidth: '50vw', zIndex: 1000, border: '7px solid', borderColor: themeMode.surface, borderRadius: '15px', backgroundColor: themeMode.surface }}>
          <Chat client={chatClient} theme={themeMode.theme === 'dark' ? 'str-chat__theme-dark' : 'str-chat__theme-light'}>
            <div style={{ height: '70vh' }}>
              {viewingChannelList ? (
                <ChannelList {...channelListProps} />
              ) : (
                <Channel channel={activeChannel}>
                  <Window>
                    <CustomChannelHeader setChatVisible={setChatVisible} backToChannelList={backToChannelList} />
                    <MessageList />
                    <CustomMessageInput />
                  </Window>
                  <Thread />
                </Channel>
              )}
            </div>
          </Chat>
        </div>
      )}
    </>
  );
};

export default Messaging;
